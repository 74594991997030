import React, { useState, useEffect } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import SEO from "../../components/shared/seo";
import { FadeUpBox, FadeIn } from "../../components/shared/transitions";
import {
  OverlayFill,
  OverlayFillGradient,
} from "../../components/shared/overlay";
import { useStaticQuery, graphql } from "gatsby";
import { CaseStudyNav } from "../../components/shared/case-study-nav";
import { Controller, Scene } from "react-scrollmagic";
import LayoutCaseStudy from "../../components/layouts/layout-case-study";
import { Helmet } from "react-helmet";
import Swiper from "react-id-swiper";
import technicalIcon from "../../images/technical-icon.svg";
import apiLogo from "../../images/api_logo.jpg";
import murdochVideo from "../../video/murdoch-uni-case-study-video.mp4";
import murdochPoster from "../../images/murdoch-video-poster.png";


function technicalScope() {
  var element = document.getElementById("technicalProcess");
  element.classList.remove("technical-slider-active", "uxprocess-slider-active");
  element.classList.add("technical-slider-active");

}



function uxProcess() {
  var element = document.getElementById("technicalProcess");
  element.classList.remove("technical-slider-active", "uxprocess-slider-active");
  element.classList.add("uxprocess-slider-active");

}


function technicalChallenge() {
  var element = document.getElementById("challenge");
  element.classList.remove("technical-challenge-slider-active", "ux-challenge-slider-active");
  element.classList.add("technical-challenge-slider-active");

}


function uxChallenge() {
  var element = document.getElementById("challenge");
  element.classList.remove("technical-challenge-slider-active", "ux-challenge-slider-active");
  element.classList.add("ux-challenge-slider-active");

}

const params1 = {
  slidesPerView: "auto",
  spaceBetween: 15,
  freeMode: true,
  freeModeMomentumBounce: false,
  freeModeMomentum: false,
  freeModeMinimumVelocity: 0.2,
  longSwipesRatio: 0.8,
  loop: true,
  speed: 500,
  slidesOffsetAfter: 525,
  freeModeSticky: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
};

function MurdochCoursePage({ location }) {
  // const { state = {} } = location;
  const [animate, setAnimate] = useState(false);
  const murdochImages = useStaticQuery(graphql`
    query {
        murdochLogoSm: file(relativePath: { eq: "murdoch-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nextWorkImage: file(relativePath: { eq: "stjohn-page-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      investTradeLogoSm: file(relativePath: { eq: "invest-trade-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Diviv Group",
            "description": ".NET applications, Progress Sitefinity CMS, Drupal, GovCMS, Sitecore Experience Platform, Microsoft Sharepoint, Dynamics CRM and Azure Hosting support in Perth Australia. Coupled with our creative and user experience team we are able to provide expert consultation and resources on a wide range of digital development projects across many industries.",
            "url": "https://www.diviv.com.au",
            "image": "https://www.diviv.com.au/icons/icon-96x96.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "165 / 15 Aberdeen St",
              "addressLocality": "Perth",
              "addressRegion": "WA",
              "postalCode": "6000",
              "addressCountry": "AU"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "ratingCount": "1"
            },
            "review": {
              "@type": "Review",
              "author": "Michael Johnson",
              "datePublished": "2020-03-09",
              "reviewBody": "Diviv is a solution based partner that delivers front and back end website solutions with innovation, professionalism and integrity. They are a great team and a pleasure to work with.",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
              }
            }
          }
          `}
        </script>
      </Helmet>
      <SEO
        title="Murdoch Course Page"
        keywords="Diviv our work case studies"
        description="Diviv has extensive experience resolving complex digital problems for businesses in a wide variety of industries. Refer to our case studies to understand how we have delivered successful business outcomes for businesses like yours."
      />
      <LayoutCaseStudy>
        <div
          id="investtrade-content"
          className="page-layout"
          data-menu-className="inverted"
        >
          <Container>
            <Row>
              <Col sm={12} md={12} lg={8}>
                <div className="client-logo--lg mb-4">
                  <Image
                    src={murdochImages.murdochLogoSm.childImageSharp.fluid.src}
                  />
                </div>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <h6>Sitefinity Custom Development</h6>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <h2>Murdoch Course Page</h2>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <p>
                      Murdoch University took on a project to replace their current course handbook infrastructure to an automated website service. By replacing the handbook and optimising search with improved course page layouts, provides a better user experience for students whilst meeting the needs of Murdoch’s business units. Prior to the redevelopment, when performing a search the curriculum information displayed data relating to majors within each course, but there was no further course detail.
                    </p>
                    <p>
                      The new developed version of the course pages now helps users search for information by course and view its underlying majors or vice versa, the updated page designs and layouts making it easier to see all corresponding information between courses and majors.
                    </p>
                    <p>
                      The redevelopment provides a more consistent user experience by integrating data from the Courseloop. The student handbook and website now share the same data.
                    </p>
                  </FadeIn>
                </FadeUpBox>
              </Col>

              <Col sm={12} md={12} lg={4}>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <div className="client-meta">
                      Discover all of what Murdoch Course has to offer by visiting their
                      website:
                      <div className="client-meta__link">
                        <a href="https://www.murdoch.edu.au/study/courses/course-finder/" target="_blank">
                          Murdoch Course
                        </a>
                      </div>
                    </div>
                  </FadeIn>
                </FadeUpBox>
              </Col>
            </Row>
          </Container>

          <Controller>
            <Scene
              triggerElement="carousel01"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="carousel01">
                  <Container>
                    <Row id="challenge" className="carousel-section technical-challenge-slider-active">
                      <Col lg={6} sm={12} className="col-left" >
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>DEFINE THE CHALLENGE</h2>
                            <p>
                              By using an agile methodology, our project team was able to adapt
                              to changing requirements whilst working closely with Murdoch University.
                            </p>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={6} sm={12} className="col-right">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <div className="carousel-slider-container">
                              <div className="carousel__outer swiper1">
                                <Swiper {...params1}>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">01</div>
                                    <div className="slide__title">
                                      Challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Specifications Changes
                                    </div>
                                    <p>
                                      During a 5-month development process, Diviv used an agile methodology to adapt to changing functional requirements that was updated on several occasions.
                                    </p>
                                  </div>

                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">02</div>
                                    <div className="slide__title">
                                      Challenge
                                    </div>
                                    <div className="slide__sub-title">
                                      Integration and Collaboration
                                    </div>
                                    <p>
                                      Courseloop functionality is mimicked using an API to integrate into Sitefinity that manages the lifecycle of a custom course module. By working closely with Murdoch University with regular communication helped ensured success.
                                    </p>
                                  </div>
                                  {/* <div className="swiper-slide">
                                                                        <div className="slide__icon">
                                                                            <img
                                                                                src={technicalIcon}
                                                                                alt="Technical icon"
                                                                            />
                                                                        </div>
                                                                        <div className="slide__number">03</div>
                                                                        <div className="slide__title">
                                                                            Challenge
                                                                        </div>
                                                                        <div className="slide__sub-title">
                                                                            Functional Specifications Document changes/additions
                                                                        </div>
                                                                        <p>
                                                                            During the development process across the 5 months, the functional specifications documentation was updated on 
                                                                            several occasions to meet additional functionality or changes to requirements. This required Diviv to work in 
                                                                            an agile way adapting to evolving requirements and priorities of Murdoch.
                                                                        </p>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="slide__icon">
                                                                            <img
                                                                                src={technicalIcon}
                                                                                alt="Technical icon"
                                                                            />
                                                                        </div>
                                                                        <div className="slide__number">04</div>
                                                                        <div className="slide__title">
                                                                            Challenge
                                                                        </div>
                                                                        <div className="slide__sub-title">
                                                                            Deep dive on the usual tech
                                                                        </div>
                                                                        <p>
                                                                            During the whole process, we did encounter many issues and roadblocks because of the limitation of the Technology 
                                                                            that we were working with. On many occasions it was difficult to identify where the issues were coming from, 
                                                                            but with the close team collaboration and regular communication we worked effectively around these limits.
                                                                        </p>
                                                                    </div> */}
                                </Swiper>
                                <div className="swiper-pagination swiper-pagination1"></div>
                              </div>
                            </div>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>

            <Scene
              triggerElement="#discovery"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="discovery">
                  <Container>
                    <Row>
                      <Col lg={5} sm={12}>
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <img src={apiLogo} alt="API" />
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={7} sm={12} className="desc">
                        <div>
                          <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                            <FadeIn>
                              <h3>API Creation and Integration</h3>
                              <p>
                                At Diviv we have undertaken many custom development projects requiring the integration
                                of multiple systems. Using our experience and learnings from other projects we are able
                                to quickly build API’s and design data input to best suit any project needs.
                              </p>
                            </FadeIn>
                          </FadeUpBox>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>

          <Controller>
            <Scene
              triggerElement="#solution"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="solution" style={{ background: "#128996" }}>
                  <Container>
                    <Row>
                      <Col lg={4} sm={12} className="title">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>How we solve your technical problem</h2>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={8} sm={12} className="desc">

                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <p>
                              By leveraging our knowledge of Murdoch’s systems from working closely with them over a
                              number of years we were able to get started quickly on project delivery.
                            </p>
                            <p>
                              We designed and built a new API which accepts course information in a JSON format,
                              communicating over an Azure service bus to display important course information on Murdoch’s website.
                            </p>
                            <h4>Technical Challenges:</h4>
                            <Row>
                              <Col sm={12}>
                                <ul className="solution-list mb-0">
                                  <li><strong>Security</strong>
                                    <p>
                                      On top of the password and API key, Diviv developed a program that cross-checks the incoming SSL
                                      certificate to determine the validity of the request origin.
                                    </p>
                                  </li>
                                  <li><strong>Problem solving around tech limitations</strong>
                                    <p>
                                      During the process, we encountered issues because of technology limitations . On many occasions
                                      it was difficult to isolate the issues but through close collaboration with our dev team and the
                                      customer, we were able to quickly solve these problems.
                                    </p>
                                  </li>
                                </ul>
                              </Col>
                              {/* <Col md={6} sm={12}>
                                                                <ul className="solution-list mb-0">
                                                                    
                                                                </ul>
                                                            </Col> */}
                            </Row>

                          </FadeIn>
                        </FadeUpBox>

                      </Col>
                    </Row>
                  </Container>


                </section>
              )}
            </Scene>
          </Controller>

          <Controller>
            <Scene
              triggerElement="#solution-video"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="solution-video" style={{ paddingTop: 100 }}>
                  <Container>
                    <Row>
                      <Col lg={12} sm={12}>
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>SHOWCASE</h2>
                            <div>
                              <video playsInline controls muted width="1078" height="810" poster={murdochPoster}>
                                <source src={murdochVideo} type="video/mp4" />
                              </video>
                            </div>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>

        </div>

        {/*  <Scene
                    triggerElement="#bottom-section"
                    reverse={false}
                    duration={2000}
                    offset={200}
                >
                    {(progress, event) => (
                        <section className="quote-wrapper">
                            <Container>
                                <QuoteBlock
                                    author="Michael Johnson"
                                    authorPosition="Senior Business Analyst"
                                    quoteText="Diviv is a solution based partner that delivers
                              front and back end website solutions with
                              innovation, professionalism and integrity. They
                              are a great team and a pleasure to work with."
                                    clientLogoUrl={eaImages.eaLogoSm.childImageSharp.fluid.src}
                                />
                            </Container>
                        </section>
                    )}
                </Scene> */}

        <CaseStudyNav
          nextUrl="/our-work/stjohn-ambulance/beats/"
          nextImage={murdochImages.nextWorkImage.childImageSharp.fluid.src}
          nextTitle="BEATS St John Ambulance"
        />
      </LayoutCaseStudy>
    </>
  );
}

export default MurdochCoursePage;